import type LoadingFile from '~/bin/advert-studio/LoadingFile';

export const enum AdvertType {
  'post' = 'post',
  'story' = 'story',
  'repost' = 'repost',
  'unknown' = 'unknown',
}

export const enum Attachment {
  'image' = 'image',
  'video' = 'video',
}

// export const enum AdvertContentType {
//   'image' = 'image',
//   'video' = 'video',
//   'text' = 'text',
//   'media_gallery' = 'media_gallery',
//   'repost' = 'repost',
//   'unknown' = 'unknown',
// }

export const enum TextFormat {
  'textPlain' = 'textPlain',
  'html' = 'html',
}

export interface MediaGalleryItem {
  mimeType: string;
  uri: string;
}

export type TelegramButton = {
  keyId: number | null;
  text: string;
  url: string;
};

export type AdvertContent = {
  text?: string;
  buttons?: TelegramButton[];
  mediaItems?: MediaGalleryItem[];
  url?: string;
};

export interface Advert {
  // Advert Creative ID
  id: number;
  // Advert Creative UUID
  uuid?: string;
  // Advert name
  name: string;
  // User ID
  userId: number;
  // Social network
  social: string;
  // Status
  status: string;
  // Advert type
  advertType: AdvertType;
  // Content
  content: any; 
  // Share token
  shareToken?: string;
  // Creation date
  createdAt: string;
  // Update date
  updatedAt: string;
}

export type UploadFile = (payload: { rawFile: File; loadingFile: LoadingFile }) => Promise<string>;

export type RawFileAndLoadingFile = {
  rawFile: File;
  loadingFile: LoadingFile;
};

export type SelectionNodeWithOffset = {
  anchorNode: Node;
  anchorOffset: number;
};
