import { defineStore } from 'pinia';
import { getMapFromArray } from '~/common/functions';
import {
  type MediaGalleryItem,
  type TelegramButton,
  AdvertType 
} from '~/interfaces/advert-studio';
import { } from '~/interfaces/advert-studio';
import { TELEGRAM_STORIES_LIMIT, UPLOAD_ATTACHMENTS_LIMIT, ATTACHMENT } from '~/common/constants';
interface AdvertContentModuleState {
  buttons: TelegramButton[];
  mediaItems: MediaGalleryItem[];
  repost: {
    url: string;
  };
  text: string;
  advertType: AdvertType,
  errors: [],
}

export const useAdvertContentStore = defineStore('advertContent', {
  state: (): AdvertContentModuleState => ({
    buttons: [],
    mediaItems: [],
    repost: {
      url: '',
    },
    text: '',
    advertType: AdvertType.post,
    errors: []
  }),
  getters: {
    buttonsMap: (state) => getMapFromArray(state.buttons, 'keyId'),
    
    uploadAttachmentsLimit: (state) => {
      if (state.advertType === AdvertType.story) return TELEGRAM_STORIES_LIMIT;
      return UPLOAD_ATTACHMENTS_LIMIT;
    },
    valid: (state) => {
      state.errors = [];

    // TODO разгрести этот код "валидаций" и заменить на вычислимое значение

    //   if (buttons.length > BUTTONS_COUNT_LIMIT) {
    //     errors.push(`Максимальное количество кнопок в креативе - ${BUTTONS_COUNT_LIMIT}`);
    //   }
    //   if (buttons.some(({ text }) => !text)) {
    //     errors.push('У каждой кнопки в креативе должно быть название');
    //   }
    //   if (buttons.some(({ url }) => validateUrl(url) !== true)) {
    //     errors.push('Укажите для каждой кнопки валидную ссылку формата http(s):// ...');
    //   }

      // if (mediaGallery.length  TELEGRAM_STORIES_LIMIT) return [];
    //     return [`Максимальное количество вложений - ${TELEGRAM_STORIES_LIMIT}`];
      
      // state.buttons && state.text && state.mediaItems)

    // const validateText = () => {
    //   const errors = [];
    //   const text = getText();
    //   return [];
    // };
  
    // const validateMediaGallery = () => {
    //   const mediaGallery = getMediaGallery();
    //   if (unref(advertType) === AdvertType.post) return validateText;
  
    //   return () => {
    //     if (!mediaGallery.length) {
    //       return ['Добавьте хотя бы одну картинку или видео'];
    //     }
    //     if (mediaGallery.length <= TELEGRAM_STORIES_LIMIT) return [];
    //     return [`Максимальное количество вложений - ${TELEGRAM_STORIES_LIMIT}`];
    //   };
    // };
  
    // const validateButtonsAndText = () => {
    //   const errors = [];
    //   const buttons = getButtons();
    //   if (buttons.length > BUTTONS_COUNT_LIMIT) {
    //     errors.push(`Максимальное количество кнопок в креативе - ${BUTTONS_COUNT_LIMIT}`);
    //   }
    //   if (buttons.some(({ text }) => !text)) {
    //     errors.push('У каждой кнопки в креативе должно быть название');
    //   }
    //   if (buttons.some(({ url }) => validateUrl(url) !== true)) {
    //     errors.push('Укажите для каждой кнопки валидную ссылку формата http(s):// ...');
    //   }
  
    //   return errors;
    }
  },
  actions: {
    setButtons(value: TelegramButton[]) {
      this.buttons = value ?? [];
    },
    setMediaItems(value: MediaGalleryItem[]) {
      this.mediaItems = value ?? [];
    },
    setRepostUrl(value: string) {
      this.repost.url = value ?? '';
    },
    setText(value: string) {
      this.text = value ?? '';
    },
    setButtonText({ keyId, text }: { keyId: number; text: string }) {
      const button = this.buttonsMap.get(keyId);
      if (button) button.text = text;
    },
    setButtonUrl({ keyId, url }: { keyId: number; url: string }) {
      const button = this.buttonsMap.get(keyId);
      if (button) button.url = url;
    },
    addButton(button: TelegramButton) {
      this.buttons = [...this.buttons, button];
    },
    removeButton(buttonKeyId: number) {
      this.buttons = this.buttons.filter(({ keyId }) => keyId !== buttonKeyId);
    },
    resetButtons() {
      this.buttons = [];
    },
    resetAdvertContentModule() {
      this.resetButtons();
      this.resetMediaItems();
      this.setRepostUrl('');
      this.setText('');
    },
    addMediaItem(payload: MediaGalleryItem) {
      this.setMediaItems([...this.mediaItems, payload]);
    },
    addMediaItems(payload: MediaGalleryItem[]) {
      this.setMediaItems([...this.mediaItems, ...payload]);
    },
    removeMediaItem(mediaItemUri: string) {
      this.setMediaItems(this.mediaItems.filter(({ uri }) => uri !== mediaItemUri));
    },
    resetMediaItems() {
      this.mediaItems = [];
    },
  },
});
